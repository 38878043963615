import vueFormGenerator from 'vue-form-generator';

export const model = {
	DiscountCategoryId: 0,
	DiscountCategoryNameSk: null,
	DiscountCategoryNameCz: null,
	DiscountCategoryNamePl: null,
	DiscountCategoryNameDe: null,
	DiscountCategoryNameEn: null,
	DiscountCategoryNameHu: null,
};

export const fields = [
	{
		fields: [
			{
				type: 'input',
				inputType: 'text',
				model: 'DiscountCategoryNameSk',
				required: true,
				validator: vueFormGenerator.validators.required,
				i18n: {
					label: 'namesk2556',
				},
			},
			{
				type: 'input',
				inputType: 'text',
				model: 'DiscountCategoryNameCz',
				required: true,
				validator: vueFormGenerator.validators.required,
				i18n: {
					label: 'namecz2558',
				},
			},
			{
				type: 'input',
				inputType: 'text',
				model: 'DiscountCategoryNamePl',
				required: true,
				validator: vueFormGenerator.validators.required,
				i18n: {
					label: 'namepl2561',
				},
			},
			{
				type: 'input',
				inputType: 'text',
				model: 'DiscountCategoryNameEn',
				required: true,
				validator: vueFormGenerator.validators.required,
				i18n: {
					label: 'nameen2564',
				},
			},
			{
				type: 'input',
				inputType: 'text',
				model: 'DiscountCategoryNameDe',
				required: true,
				validator: vueFormGenerator.validators.required,
				i18n: {
					label: 'namede2562',
				},
			},
			{
				type: 'input',
				inputType: 'text',
				model: 'DiscountCategoryNameHu',
				required: true,
				validator: vueFormGenerator.validators.required,
				i18n: {
					label: 'namehu2566',
				},
			},
		],
	},
];
